<template lang="pug">
app-layout.interactions
  v-app.layout-full
    page-header.sh-xsmall(title="Сообщения")
    v-main.page-interactions
      v-overlay.text-center(
        :value="loader"
        color="#fff"
        absolute
        opacity="1"
      )
        ui-loader(:text="loadingText")

      v-container
        router-link.dialog(
          v-for="dialog in dialogs"
          :key="dialog.number"
          :to="{ name: 'pageInteractionDialog', params: { id: dialog.number } }"
        )
          .d-asset
            v-img.thumbnail(:src="dialog.asset.photo")
            .d-asset-details
              .d-asset-name.nowrap {{ dialog.asset.title }}
              .d-asset-price {{ dialog.asset.price }}
              .address.nowrap {{ dialog.asset.address }}
          .last-message(:class="{ viewed: dialog.viewed }")
            .name-and-date
              .caption-mid.color-gray100 {{ dialog.last_message.lead.name_short }}
              .d-flex-c
                ui-icon.color-primary60.mr-1(v-if="dialog.viewed" name="check-double")
                .date-time {{ dialog.last_message.created_at | datetime }}
            .d-flex.space-between
              .message-body.mt-5px {{ dialog.last_message.body }}
              //- .counter(v-if="!dialog.viewed") // поля счетчика пока нет в API
      .interactions__list--empty(v-if="!dialogs.length")
        titled-image(small title="Пока у вас нет сообщений")
          template(#image)
            img(src="~@/assets/96/messages.svg")
          | Здесь будут ваши сообщения и диалоги
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import PageHeader from '@/components/app/PageHeader.vue';
  import TitledImage from '@/components/TitledImage.vue';

  import { UiBtn, UiIcon, UiLoader } from '@/uikit';
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'page-interactions',
    components: {
      AppLayout,
      PageHeader,
      UiBtn,
      UiIcon,
      UiLoader,
      TitledImage,
    },

    async beforeRouteLeave(to, from, next) {
      if (to.name == 'pageInteractionDialog') {
        this.loadingText = 'Загрузка сообщений...';
        this.loader = true;
        if (to.params?.id) {
          const response = await this.requestDialog(to.params.id);
          if (response.ok) {
            next();
          } else {
            this.$notify.error('Ошибка загрузки сообщения');
          }
        }
      } else {
        next();
      }
    },

    data() {
      return {
        searchOptions: {},
        loader: false,
        loadingText: '',
      };
    },

    computed: {
      // ...mapGetters(['currentUser']),
      ...mapState('interactions', ['dialogs', 'total']),
    },

    created() {
      this.fetchInteractions();
    },

    methods: {
      ...mapActions('interactions', ['searchInteractions', 'requestDialog']),
      async fetchInteractions() {
        this.loadingText = 'Загрузка диалогов...';
        this.loader = true;
        const response = await this.searchInteractions(this.searchOptions);
        this.loader = false;
        if (!response.ok) {
          this.$notify.error('Ошибка загрузки диалога');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-interactions {
    height: 100%;
    min-height: 480px;
  }

  .interactions__list--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 32px;
  }

  .dialog {
    display: block;
    text-decoration: none;

    & + .dialog {
      margin-top: 24px;
      border-top: 1px solid c('gray10', 'light');
      padding-top: 16px;
    }
  }

  .d-asset {
    display: flex;
    max-width: 100%;
  }

  .thumbnail {
    width: 64px;
    height: 64px;
    flex: none;
    border-radius: 3px;
    margin-right: 16px;
  }
  .d-asset-details {
    max-width: calc(100% - 80px);
  }
  .d-asset-name {
    font-family: 'Montserrat', serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: c('gray100', 'light');
  }

  .d-asset-price {
    font-family: 'Montserrat', serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    color: c('gray100', 'light');
  }

  .address {
    margin-top: 4px;
    font-size: 13px;
    line-height: 16px;
    color: c('gray70', 'light');
  }

  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .last-message {
    padding: 12px 16px;
    margin-top: 16px;
    border-radius: 6px;
    border: 1px solid c('gray5', 'light');
    background-color: c('gray5', 'light');
    &.viewed {
      background-color: white;
    }
  }

  .message-body {
    font-size: 14px;
    line-height: 20px;
    color: c('gray80', 'light');
  }

  .counter {
    width: 16px;
    height: 16px;
    flex: none;
    margin-left: 4px;
  }

  .date-time {
    font-size: 13px;
    line-height: 18px;
    color: c('black70', 'light');
  }

  .name-and-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .animation-rotate {
    animation: rotate 1s infinite cubic-bezier(0.55, 0.15, 0.45, 0.85);
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
</style>
